<template>
	<div class="person cont">
		
		<sideNav></sideNav>
		<router-view></router-view>
	</div>
	<footers></footers>
</template>
<script type="text/javascript">
	import sideNav from '@/views/privacy/sideNav2.vue'
	import footers from '@/components/foot.vue'
	export default {
		data(){
			return {
				
			}
		},
		components:{
			sideNav,
			footers,
		}
	}
</script>
<style type="text/css" scoped="">
	.person{margin-top: 12px;display: flex;}
	:deep(.tit) {border-bottom: #D8D8D8 1px solid;}
	:deep(.tit span) {font-size: 14px;color: #222222;padding-bottom: 15px;line-height: 20px;display: inline-block;position: relative;}
	:deep(.tit span::before) {content: '';height: 2px;width: 100%;position: absolute;bottom: 0;left: 0;background: #F5630B;}
</style>