<template>
	<div class="sidenav">
		<el-menu class=" sideUl" :default-active="routerpath" :router="true">
			<el-menu-item index="/privacy/agreement" class="li"> 用户协议 </el-menu-item>
			<el-menu-item index="/privacy/privacy" class="li">隐私政策</el-menu-item>
			<el-menu-item index="/privacy/settled" class="li">入驻协议</el-menu-item>
		</el-menu>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		computed:{
			routerpath(){
				return this.$route.meta.activePath? this.$route.meta.activePath : this.$route.path
			}
		},
		mounted(){
		},

	}
</script>

<style scoped>
	.sidenav {flex: 0 0 196px;margin-right: 12px;}
	.sidenav .sideUl {background: #FFFFFF;border-radius:6px;height: 600px;border: none;padding-top: 20px;}
	.sidenav .li {font-size: 14px;color: #2E3036;height: 44px;line-height: 44px;padding-left: 68px !important;display: block;}
	.sidenav .li:hover {background: rgba(245, 99, 11, 0.06);color: #EA5504;}
	.sidenav .li.is-active::before {content: '';width: 4px;height: 100%;position: absolute;left: 0;top: 0;background: #EA5504;;}
	.sidenav .li.is-active {background: rgba(245, 99, 11, 0.06);color: #EA5504;position: relative;}
	.sidenav .li {margin-bottom: 30px;}
</style>
